<template>
  <ui-component-modal
    :modalTitle="
      $t('Components.Reservation.ModalEditSpace.Header_ModalHeader') +
        ' ' +
        mSpace.SpaceName
    "
    modalSize="extra-large"
    :showModal="showModal"
    :onClickCancel="onClickCancel"
    :onClickSave="saveSpace"
    :isSaving="isSaving || isValidating > 0"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="showNotAvailable || blockSaveButton"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <article v-if="openinghours" class="message is-info is-small">
        <div class="message-body">
          {{ $t('Components.Reservation.ModalEditSpace.Label_OpeningHours') }}
          {{ openinghours.MinMinutes | minutesToTime }} -
          {{ openinghours.MaxMinutes | minutesToTime }}
        </div>
      </article>

      <div v-if="setupDone && meetingtypeId < 3">
        <article
          v-if="showNotAvailable && isValidating === 0"
          class="message is-warning"
        >
          <div class="message-body">
            <ul>
              <li v-if="showWarning(0)">
                {{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_LocationIsClosedOnDate'
                  )
                }}
              </li>
              <li v-if="showWarning(1)">
                {{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_LocationIsClosed'
                  )
                }}
                <div>
                  <a @click="resetSpaceValue" class="is-size-7">
                    <span class="icon is-small"
                      ><font-awesome-icon :icon="['fas', 'undo']"
                    /></span>
                    {{
                      $t('Components.Reservation.ModalEditSpace.Button_Undo')
                    }}
                  </a>
                  <a
                    @click="ignoreWarning(1)"
                    class="button is-small is-warning has-margin-left"
                  >
                    <span class="icon is-small"
                      ><font-awesome-icon
                        :icon="['fas', 'exclamation-triangle']"
                    /></span>
                    <span>{{
                      $t(
                        'Components.Reservation.ModalEditSpace.Button_IgnoreWarning'
                      )
                    }}</span>
                  </a>
                </div>
              </li>
              <li v-if="showWarning(2)">
                <span>{{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_SpaceNotAvailable'
                  )
                }}</span>
                <span>
                  <a
                    v-if="!mSpace.IsAvailable"
                    @click="setShowSpaceAlternatives(mSpace)"
                    class="button is-small is-warning"
                    >{{
                      $t(
                        'Components.Reservation.ModalEditSpace.Button_ShowAlternatives'
                      )
                    }}</a
                  >
                </span>
              </li>
              <li v-if="showWarning(3)">
                <span>{{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_MinimumHours'
                  )
                }}</span>
                <div>
                  <a @click="resetSpaceValue" class="is-size-7">
                    <span class="icon is-small"
                      ><font-awesome-icon :icon="['fas', 'undo']"
                    /></span>
                    {{
                      $t('Components.Reservation.ModalEditSpace.Button_Undo')
                    }}
                  </a>
                  <a
                    @click="ignoreWarning(3)"
                    class="button is-small is-warning has-margin-left"
                  >
                    <span class="icon is-small"
                      ><font-awesome-icon
                        :icon="['fas', 'exclamation-triangle']"
                    /></span>
                    <span>{{
                      $t(
                        'Components.Reservation.ModalEditSpace.Button_IgnoreWarning'
                      )
                    }}</span>
                  </a>
                </div>
              </li>
              <li v-if="showWarning(4)">
                <span>{{
                  $t(
                    'Components.Reservation.ModalEditSpace.Warning_NumberOfSeats'
                  )
                }}</span>
                <div>
                  <a @click="resetSpaceValue" class="is-size-7">
                    <span class="icon is-small"
                      ><font-awesome-icon :icon="['fas', 'undo']"
                    /></span>
                    {{
                      $t('Components.Reservation.ModalEditSpace.Button_Undo')
                    }}
                  </a>
                  <a
                    @click="ignoreWarning(4)"
                    class="button is-small is-warning has-margin-left"
                  >
                    <span class="icon is-small"
                      ><font-awesome-icon
                        :icon="['fas', 'exclamation-triangle']"
                    /></span>
                    <span>{{
                      $t(
                        'Components.Reservation.ModalEditSpace.Button_IgnoreWarning'
                      )
                    }}</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </article>

        <div v-if="!showNotAvailable">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th width="125">
                  {{ $t('Components.Reservation.ModalEditSpace.Label_Pax') }}
                </th>
                <th v-if="reservation.MeetingtypeId === 1">
                  {{
                    $t(
                      'Components.Reservation.ModalEditSpace.Label_Configuration'
                    )
                  }}
                </th>
                <th>
                  {{
                    $t('Components.Reservation.ModalEditSpace.Label_StartTime')
                  }}
                </th>
                <th>
                  {{
                    $t('Components.Reservation.ModalEditSpace.Label_EndTime')
                  }}
                </th>
                <th class="has-text-right">
                  {{ $t('Components.Reservation.ModalEditSpace.Label_Tax') }}
                </th>
                <th width="175" class="has-text-right">
                  {{ $t('Components.Reservation.ModalEditSpace.Label_Price') }}
                </th>
                <th width="100" class="has-text-right">
                  {{ $t('Components.Reservation.ModalEditSpace.Label_Total') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="field has-addons">
                    <p class="control">
                      <input
                        type="text"
                        @change="validateAvailability"
                        @keyup="delay(validateAvailability, 100)"
                        v-model.number="mSpace.Seats"
                        class="input"
                        :class="{ 'is-danger': showNoSeatsWarning }"
                        :readonly="reservation.MeetingtypeId !== 1"
                        :disabled="reservation.MeetingtypeId !== 1"
                      />
                    </p>
                    <p class="control">
                      <a class="button is-static">X</a>
                    </p>
                  </div>
                </td>
                <td v-if="reservation.MeetingtypeId === 1">
                  <div class="select">
                    <select
                      @change="validateAvailability"
                      v-model="mSpace.SettingId"
                    >
                      <option :value="0" :selected="space.SettingId === 0"
                        >Select a configuration</option
                      >
                      <option
                        v-for="(setting, index) in settings"
                        :key="index"
                        :value="setting.SettingId"
                        :selected="setting.SettingId === space.SettingId"
                        >{{ setting.SettingId | getSettingName }}</option
                      >
                    </select>
                  </div>
                </td>
                <td>
                  <div
                    class="select"
                    :class="{ 'is-danger': showStartTimeAferEndTimeWarning }"
                  >
                    <select
                      @change="validateAvailability"
                      v-model="mSpace.StartMinutes"
                    >
                      <option
                        v-for="(timeSlot, index) in timeSlots"
                        :key="index"
                        :value="timeSlot"
                        :selected="mSpace.StartMinutes === timeSlot"
                        >{{ timeSlot | minutesToTime }}</option
                      >
                    </select>
                  </div>
                </td>
                <td>
                  <div
                    class="select"
                    :class="{ 'is-danger': showStartTimeAferEndTimeWarning }"
                  >
                    <select
                      @change="validateAvailability"
                      v-model="mSpace.EndMinutes"
                    >
                      <option
                        v-for="(timeSlot, index) in timeSlots"
                        :key="index"
                        :value="timeSlot"
                        :selected="mSpace.EndMinutes === timeSlot"
                        >{{ timeSlot | minutesToTime }}</option
                      >
                    </select>
                  </div>
                </td>
                <td class="has-text-right">
                  <div class="select">
                    <select v-model="mSpace.TaxPercentage">
                      <option
                        v-for="taxPercentage in taxPercentages"
                        :key="taxPercentage"
                        :value="taxPercentage"
                        :selected="taxPercentage === mSpace.TaxPercentage"
                        >{{ taxPercentage }}%</option
                      >
                    </select>
                  </div>
                </td>
                <td class="has-text-right">
                  <div class="field has-addons">
                    <p class="control">
                      <a
                        class="button is-static"
                        v-html="mSpace.CurrencySymbol"
                      ></a>
                    </p>
                    <p class="control">
                      <InputNumeric
                        v-model.number="mSpace.PricePerSeat"
                        @change="updateCrcValue"
                        @keyup="delay(updateCrcValue, 200)"
                        :class="{ 'is-danger': showNoPriceWarning }"
                      />
                    </p>
                  </div>
                </td>
                <td class="has-text-right">
                  {{
                    meetingspaceTotalPrice
                      | toCurrency('nl', mSpace.CurrencyISO)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import availabilityProvider from '@/providers/availability'
import crcProvider from '@/providers/crc'
import openinghoursProvider from '@/providers/openinghours'
import reservationProvider from '@/providers/reservation'
import spaceProvider from '@/providers/space'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    onClickSave: {
      type: Function,
      required: false,
    },
    channelId: {
      default: 0,
      type: Number,
    },
    isEmployee: {
      default: false,
      type: Boolean,
    },
    locationId: {
      default: 0,
      type: Number,
    },
    meetingtypeId: {
      default: 0,
      type: Number,
    },
    index: {
      default: 0,
      type: Number,
    },
    space: null,
  },

  components: {
    InputNumeric: () => import('@/components/UI/Form/InputNumeric'),
  },

  data() {
    return {
      timer: null,
      check: null,
      checkString: '1#1#1#1',
      isAvailable: true,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      isValidating: 0,
      mSpace: null,
      openinghours: null,
      settings: [],
      setupDone: false,
      showLocationOpenWarning: false,
      showLocationOpeningHoursWarning: false,
      showMinimumHoursWarning: false,
      showSeatsSettingWarning: false,
      showUnitAvailableWarning: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
    ...mapState('locationStore', ['locationState']),

    timeSlots() {
      let timeSlots = []
      let min = 0
      let max = 1440
      let minutes = 30

      let i = min
      while (i <= max) {
        timeSlots.push(i)
        i = i + minutes
      }
      return timeSlots
    },

    meetingspaceTotalPrice() {
      let total = 0.0

      if (this.mSpace) {
        if (this.mSpace.CalculationType === 'PerDayPartOnly') {
          total = Number(this.mSpace.PricePerSeat)
        } else {
          total =
            Math.round(
              Number(this.mSpace.Seats) * Number(this.mSpace.PricePerSeat) * 100
            ) / 100
        }
      }

      return total
    },

    taxPercentages() {
      let result = []

      if (this.locationState) {
        result = this.locationState.TaxPercentages.map((t) => t.TaxPercentage)
      }

      return result
    },

    showNotAvailable() {
      let show = false

      show = !this.isAvailable

      return show
    },

    blockSaveButton() {
      let self = this
      return (
        self.showNoSeatsWarning ||
        self.showNoPriceWarning ||
        self.showStartTimeAferEndTimeWarning ||
        self.showEndTimeBeforeStartTimeWarning ||
        self.showEndTimeBeforeStartTimeWarning
      )
    },

    showNoSeatsWarning() {
      let self = this
      return String(self.mSpace.Seats).length === 0
    },

    showNoPriceWarning() {
      let self = this
      return String(self.mSpace.PricePerSeat).length === 0
    },

    showStartTimeAferEndTimeWarning() {
      let self = this
      return self.mSpace.StartMinutes >= self.mSpace.EndMinutes
    },

    showEndTimeBeforeStartTimeWarning() {
      let self = this
      return self.mSpace.EndMinutes <= self.mSpace.StartMinutes
    },
  },

  created() {
    this.mSpace = JSON.parse(JSON.stringify(this.space))

    this.getLocationOpeninghours()
    this.getSpaceData()
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    getLocationOpeninghours() {
      openinghoursProvider.methods
        .getOpeningHours(
          this.space.StartDate,
          0,
          this.locationId,
          this.meetingtypeId
        )
        .then((response) => {
          if (response.status === 200) {
            this.openinghours = response.data
          }
        })
        .then(() => {
          this.setupDone = true
        })
    },

    getSpaceData() {
      spaceProvider.methods
        .getSpaceById(this.space.SpaceId)
        .then((response) => {
          if (response.status === 200) {
            this.settings = response.data.Settings
          }
        })
    },

    delay(callback, ms) {
      let self = this
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        callback.apply(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    validateAvailability() {
      if (Number(this.mSpace.Seats) > 0) {
        this.mSpace.LocationId = this.locationId
        this.mSpace.ChannelId = this.channelId
        this.mSpace.MeetingtypeId = this.meetingtypeId
        this.mSpace.VoucherId = this.reservation.VoucherId

        this.isValidating++

        availabilityProvider.methods
          .checkSpaceTimeAvailability(this.mSpace)
          .then((response) => {
            this.check = response.data
            let checksArray = this.check.CheckString.split('#')

            this.showLocationOpenWarning = Number(checksArray[0]) === 0
            this.showLocationOpeningHoursWarning = Number(checksArray[1]) === 0
            this.showUnitAvailableWarning = Number(checksArray[2]) === 0
            this.showMinimumHoursWarning = Number(checksArray[3]) === 0
            this.showSeatsSettingWarning = Number(checksArray[4]) === 0

            if (this.check.IsAvailable) {
              this.mSpace.PricePerSeat = this.check.PricePerSeat
              this.mSpace.PriceTotal = this.check.PriceTotal
              this.mSpace.Crc = this.check.Crc
            }

            this.isAvailable = this.check.IsAvailable
            this.checkString = this.check.CheckString
          })
          .finally(() => {
            --this.isValidating
          })
      }
    },

    showWarning(index) {
      let checks = this.checkString.split('#')

      return Number(checks[index]) === 0
    },

    ignoreWarning(index) {
      let checks = this.checkString.split('#')
      checks[index] = 1

      let checkIndex = checks.findIndex((c) => c === 0)
      if (checkIndex === -1) {
        this.isAvailable = true
      }

      this.checkString = checks.join('#')
    },

    cancelEditReservationSpace() {
      this.$emit('cancelEditSpace')
    },

    resetSpaceValue() {
      let self = this
      self.checkString = '1#1#1#1'
      self.isAvailable = true
      self.mSpace = JSON.parse(JSON.stringify(self.space))
    },

    updateCrcValue() {
      crcProvider.methods
        .checkCrc(this.mSpace.SpaceId, this.mSpace.PricePerSeat)
        .then((response) => {
          if (response.status === 200) {
            this.mSpace.Crc = response.data
          }
        })
    },

    saveSpace() {
      let self = this
      self.mSpace.PriceTotal = self.meetingspaceTotalPrice

      //Vue.set(self.reservation.Spaces, self.index, self.mSpace)

      self.isSaving = true

      reservationProvider.methods
        .updateReservationSpace(self.reservation.Id, self.mSpace)
        .then((response) => {
          if (response.status === 200) {
            // Update reservation store
            self.setReservation(response.data)

            self.onClickCancel()

            self.$emit('spaceEdited')

            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Reservation data updated!',
            })
          }
        })
        .catch((error) => {
          self.isSavingError = true
          self.responseMessage = error.response.data.Value
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
